import React from 'react';
import { Helmet } from 'react-helmet';

import AboutComponent from 'components/About';
import Layout from 'components/layout';

const AboutPage: React.FC = () => (
  <Layout>
    <Helmet>
      <title>About</title>
      <meta name="og:title" content="About" />
      <meta name="twitter:title" content="About" />
    </Helmet>
    <AboutComponent />
  </Layout>
);

export default AboutPage;
