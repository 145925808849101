import * as React from 'react';

import styles from 'components/About/About.module.scss';

const MissionTitle: React.FC = ({ children }) => (
  <h3 className={styles.title}>
    {children}
  </h3>
);

export default MissionTitle;
