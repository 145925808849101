import { FormattedMessage } from 'gatsby-plugin-intl';
import * as React from 'react';

import styles from 'components/About/About.module.scss';

import Mission from './Mission';

const ValueMissions: React.FC = () => (
  <div className="container-fluid">
    <div className={`${styles.missions} row align-items-stretch`}>
      <div className={`col-12 mb-2 mt-2 ${styles.privacy}`}>
        <Mission>
          <Mission.Tag>
            <FormattedMessage id="components.about.content.value.end_to_end_encrypted" />
          </Mission.Tag>
          <Mission.Title>
            <FormattedMessage id="components.about.content.value.privacy" />
          </Mission.Title>
          <Mission.Paragraph>
            <FormattedMessage id="components.about.content.value.privacy_description" />
          </Mission.Paragraph>
        </Mission>
      </div>
      <div className={`col-md-6 col-12 mb-2 mb-md-0 ${styles.control}`}>
        <Mission>
          <Mission.Tag>
            <FormattedMessage id="components.about.content.value.innovative_sharing" />
          </Mission.Tag>
          <Mission.Title>
            <FormattedMessage id="components.about.content.value.control" />
          </Mission.Title>
          <Mission.Paragraph>
            <FormattedMessage id="components.about.content.value.control_description" />
          </Mission.Paragraph>
        </Mission>
      </div>

      <div className={`col-md-6 col-12 mb-2 mb-md-0 ${styles.quality}`}>
        <Mission>
          <Mission.Tag>
            <FormattedMessage id="components.about.content.value.high_resolution_storage" />
          </Mission.Tag>
          <Mission.Title>
            <FormattedMessage id="components.about.content.value.quality" />
          </Mission.Title>
          <Mission.Paragraph>
            <FormattedMessage id="components.about.content.value.quality_description" />
          </Mission.Paragraph>
        </Mission>
      </div>
    </div>
  </div>
);

export default ValueMissions;
