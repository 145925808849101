import { graphql, useStaticQuery } from 'gatsby';
import GatsbyImage from 'gatsby-image';
import { FormattedMessage } from 'gatsby-plugin-intl';
import * as React from 'react';

import styles from 'components/About/About.module.scss';

const AboutFounderSection: React.FC = () => {
  const {
    ourCeoImage,
    storyImageOne,
    storyImageTwo,
    storyImageThree,
    storyImageFour,
    storyImageFive,
  } = useStaticQuery(
    graphql`
      query {
        ourCeoImage: file(relativePath: { eq: "about/our_ceo.png" }) {
          childImageSharp {
            fluid(maxWidth: 750, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64,
            }
          }
        }
        storyImageOne: file(relativePath: { eq: "about/our_ceo_story_1.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1980, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64,
            }
          }
        }
        storyImageTwo: file(relativePath: { eq: "about/our_ceo_story_2.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1980, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64,
            }
          }
        }
        storyImageThree: file(relativePath: { eq: "about/our_ceo_story_3.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1980, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64,
            }
          }
        }
        storyImageFour: file(relativePath: { eq: "about/our_ceo_story_4.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1980, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64,
            }
          }
        }
        storyImageFive: file(relativePath: { eq: "about/our_ceo_story_5.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1980, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64,
            }
          }
        }
      }
    `,
  );

  return (
    <>
      <div className={`${styles.contentHeader} ${styles.founder}`}>
        <div className={`container ${styles.container}`}>
          <div className="row">
            <div className="col-lg-8 col-12">
              <h2 className={styles.title}>
                <FormattedMessage id="components.about.content.founder.our_ceo" />
              </h2>
              <p className={styles.paragraph}>
                <FormattedMessage id="components.about.content.founder.our_ceo_description" />
              </p>
              <div className={`d-flex mt-5 align-items-center ${styles.buttonBox}`}>
                <a
                  className={styles.btn}
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.youtube.com/watch?v=27e9pOj9Bvw"
                >
                  <FormattedMessage id="components.buttons.play_video" />
                </a>

                <div className={styles.socialMedia}>
                  <a
                    className={styles.facebook}
                    href="https://fr-fr.facebook.com/taigkhris/"
                    rel="noreferrer"
                    target="_blank"
                  >
                    facebook
                  </a>
                  <a
                    className={styles.twitter}
                    href="https://twitter.com/taigkhris"
                    rel="noreferrer"
                    target="_blank"
                  >
                    twitter
                  </a>
                  <a
                    className={styles.instagram}
                    href="https://instagram.com/taigkhris?igshid=lcky0mcfqbgm"
                    rel="noreferrer"
                    target="_blank"
                  >
                    instagram
                  </a>
                  <a
                    className={styles.linkedin}
                    href="https://www.linkedin.com/in/taigkhris/"
                    rel="noreferrer"
                    target="_blank"
                  >
                    linkedin
                  </a>
                  <a
                    className={styles.youtube}
                    href="https://www.youtube.com/user/taigkhris"
                    rel="noreferrer"
                    target="_blank"
                  >
                    youtube
                  </a>
                  <a
                    className={styles.albums}
                    href="https://albums.app.link/taigkhris/share/TAIGKHRIS"
                    rel="noreferrer"
                    target="_blank"
                  >
                    albums
                  </a>
                </div>
              </div>
            </div>
            <div className={`${styles.ceoImage}`}>
              <GatsbyImage fluid={ourCeoImage.childImageSharp.fluid} />
            </div>
          </div>
        </div>
        <span className={styles.scrollArrow}>
          <FormattedMessage id="components.about.content.scroll_down" />
        </span>
      </div>
      <div className="container-fluid mt-2 aboutFounder">
        <GatsbyImage fluid={storyImageOne.childImageSharp.fluid} />
        <GatsbyImage fluid={storyImageTwo.childImageSharp.fluid} />
        <GatsbyImage fluid={storyImageThree.childImageSharp.fluid} />
        <GatsbyImage fluid={storyImageFour.childImageSharp.fluid} />
        <GatsbyImage fluid={storyImageFive.childImageSharp.fluid} />
      </div>
    </>
  );
};

export default AboutFounderSection;
