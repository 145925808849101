import * as React from 'react';

import styles from 'components/About/About.module.scss';

import MissionParagraph from './MissionParagraph/index';
import MissionTag from './MissionTag/index';
import MissionTitle from './MissionTitle/index';

interface MissionsProps extends React.FC {
    Title: typeof MissionTitle;
    Tag: typeof MissionTag;
    Paragraph: typeof MissionParagraph;
}

const Mission: MissionsProps = ({ children }) => (
  <div className={`${styles.missionsItem}`}>
    {children}
  </div>
);

Mission.Title = MissionTitle;
Mission.Tag = MissionTag;
Mission.Paragraph = MissionParagraph;

export default Mission;
