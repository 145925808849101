import * as React from 'react';

import styles from 'components/About/About.module.scss';

const MissionTag: React.FC = ({ children }) => (
  <span className={styles.tag}>
    {children}
  </span>
);

export default MissionTag;
