import * as React from 'react';

import styles from 'components/About/About.module.scss';

const MissionParagraph: React.FC = ({ children }) => (
  <p className={styles.paragraph}>
    {children}
  </p>
);

export default MissionParagraph;
