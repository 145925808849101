import React, { useState } from 'react';

import AboutContent from './AboutContent';
import AboutTabs from './AboutTabs';

const AboutComponent: React.FC = () => {
  const [tab, setTab] = useState('');
  return (
    <>
      <AboutTabs setTab={setTab} tab={tab} />
      <AboutContent tab={tab} />
    </>
  );
};

export default AboutComponent;
