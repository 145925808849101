import { FormattedMessage } from 'gatsby-plugin-intl';
import * as React from 'react';

import styles from 'components/About/About.module.scss';

const ValueHeader: React.FC = () => (
  <div className={styles.contentHeader}>
    <div className="container">
      <div className="row">
        <div className="col-lg-10 col-12">
          <h2 className={styles.title}>
            <FormattedMessage id="components.about.content.value.our_mission" />
          </h2>
          <p className={styles.paragraph}>
            <FormattedMessage id="components.about.content.value.our_mission_first_patagraph" />
          </p>
          <p className={styles.paragraph}>
            <FormattedMessage id="components.about.content.value.our_mission_second_paragraph" />
          </p>
        </div>
      </div>
    </div>
    <span className={styles.scrollArrow}>
      <FormattedMessage id="components.about.content.scroll_down" />
    </span>
  </div>
);

export default ValueHeader;
