import { FormattedMessage } from 'gatsby-plugin-intl';
import React, { useEffect } from 'react';

import styles from '../About.module.scss';

import TabsEnum from './enums';

interface AboutTabsProps {
    tab: string;
    setTab: (tab: string) => void;
}

const AboutTabs: React.FC<AboutTabsProps> = ({ setTab, tab }) => {
  useEffect(() => {
    setTab(TabsEnum.value);
  }, [setTab]);

  return (
    <div className={styles.aboutTabs}>
      <div className="container">
        <ul>
          <li className={tab === TabsEnum.value ? styles.active : ''}>
            <button type="button" onClick={(): void => setTab(TabsEnum.value)}>
              <FormattedMessage id="components.about.tabs.value" />
            </button>
          </li>
          <li className={tab === TabsEnum.founder ? styles.active : ''}>
            <button type="button" onClick={(): void => setTab(TabsEnum.founder)}>
              <FormattedMessage id="components.about.tabs.founder" />
            </button>
          </li>
          <li className={tab === TabsEnum.team ? styles.active : ''}>
            <button type="button" onClick={(): void => setTab(TabsEnum.team)}>
              <FormattedMessage id="components.about.tabs.team" />
            </button>
          </li>
          <li className={tab === TabsEnum.company ? styles.active : ''}>
            <button type="button" onClick={(): void => setTab(TabsEnum.company)}>
              <FormattedMessage id="components.about.tabs.company" />
            </button>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default AboutTabs;
