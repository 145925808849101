import * as React from 'react';

import TabsEnum from '../AboutTabs/enums';

import AboutCompanySection from './AboutCompany';
import AboutFounderSection from './AboutFounder/index';
import AboutTeamSection from './AboutTeam/index';
import AboutContentValue from './AboutValue/index';

interface AboutContentProps {
    tab: string;
}

const AboutContent: React.FC<AboutContentProps> = ({ tab }) => (
  <>
    {tab === TabsEnum.value && <AboutContentValue />}
    {tab === TabsEnum.company && <AboutCompanySection />}
    {tab === TabsEnum.team && <AboutTeamSection />}
    {tab === TabsEnum.founder && <AboutFounderSection />}
  </>
);

export default AboutContent;
