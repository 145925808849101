import { graphql, useStaticQuery } from 'gatsby';
import GatsbyImage from 'gatsby-image';
import { FormattedMessage } from 'gatsby-plugin-intl';
import * as React from 'react';

import styles from 'components/About/About.module.scss';

const AboutTeamSection: React.FC = () => {
  const {
    TeamImage,
  } = useStaticQuery(
    graphql`
      query {
        TeamImage: file(relativePath: { eq: "about/team.png" }) {
          childImageSharp {
            fluid(maxWidth: 750, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64,
            }
          }
        }
      }
    `,
  );

  return (
    <div className={styles.contentHeader}>
      <div className="container">
        <div className="row justify-content-between">
          <div className="col-lg-5 col-12">
            <h2 className={styles.title}>
              <FormattedMessage id="components.about.content.team.our_team" />
            </h2>
            <p className={styles.paragraph}>
              <FormattedMessage id="components.about.content.team.our_team_description" />
            </p>
          </div>
          <div className="col-lg-6 col-12">
            <GatsbyImage fluid={TeamImage.childImageSharp.fluid} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutTeamSection;
