import { FormattedMessage } from 'gatsby-plugin-intl';
import * as React from 'react';

import styles from 'components/About/About.module.scss';

const AboutCompanySection: React.FC = () => (
  <div className={styles.contentHeader}>
    <div className="container">
      <div className="row">
        <div className="col-lg-10 col-12">
          <h2 className={styles.title}>
            <FormattedMessage id="components.about.content.company.our_copmany" />
          </h2>
          <p className={styles.paragraph}>
            <FormattedMessage id="components.about.content.company.our_company_description" />
          </p>
          <span className={styles.smallTitle}>
            <FormattedMessage id="components.about.content.company.contact_us" />
          </span>

          <div className={styles.emailBox}>
            <span className={styles.title}>
              <FormattedMessage id="components.about.content.company.investors" />
            </span>
            <span className={styles.email}>
              <FormattedMessage id="components.about.content.company.invest_email_address" />
            </span>
          </div>

          <div className={styles.emailBox}>
            <span className={styles.title}>
              <FormattedMessage id="components.about.content.company.business" />
            </span>
            <span className={styles.email}>
              <FormattedMessage id="components.about.content.company.business_email_address" />
            </span>
          </div>

          <div className={styles.emailBox}>
            <span className={styles.title}>
              <FormattedMessage id="components.about.content.company.media" />
            </span>
            <span className={styles.email}>
              <FormattedMessage id="components.about.content.company.media_email_address" />
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default AboutCompanySection;
