import React from 'react';

import ValueHeader from './Header';
import ValueMissions from './Missions';

const AboutContentValue: React.FC = () => (
  <>
    <ValueHeader />
    <ValueMissions />
  </>
);

export default AboutContentValue;
